export const projects = {
  web3: [
    {
      title: 'Flexy',
      description: 'Crosschain Crypto Asset Bridge.',
      github: '',
      live: 'https://flexy.tech',
      stack: 'NextJS, TS, Tailwind, Viem',
      isPrivate: true,
    },
    {
      title: 'Guild Protocol',
      description: 'Landing Page for a play to earn crypto DAO',
      github: '',
      live: 'https://guildprotocol.io/',
      stack: 'NextJS, TS, GSAP',
      isPrivate: true,
    },
    {
      title: 'Bloktopia DAO',
      description: 'On-Chain proposal voting for Bloktopia DAO',
      github: '',
      live: 'https://www.bloktopia.com/dao/proposals',
      stack: 'NextJS, TS, TAILWIND, VIEM',
      isPrivate: true,
    },
    {
      title: 'Caketools',
      description: 'Decentralised charting hub for the BSC Network.',
      github: '',
      live: 'https://caketools.vercel.app/',
      stack: 'NextJS, TS, Ethers',
      isPrivate: true,
    },
  ],
  tools: [
    {
      title: 'EIP-1193 Bridge',
      description: 'Ethers v6 Compatible EIP-1193 Bridge.',
      github: 'https://github.com/gasbot-xyz/eip1193bridge',
      live: 'https://www.npmjs.com/package/eip1193bridge',
      stack: 'Typescript, Ethers',
      isPrivate: false,
      isPackage: true,
    },
    {
      title: 'Checkbot',
      description: 'Accountability partner bot for Slack.',
      github: 'https://github.com/checkmate-africa/checkbot',
      live: '',
      stack: 'Golang, AWS (Lambda, Api Gateway, DynamoDB)',
      isPrivate: false,
      isRepository: true,
    },
    {
      title: 'Pickr',
      description: 'Custom a11y Compliant Datepicker.',
      github: 'https://github.com/kadetXx/pickr',
      live: 'https://620e47ad1a4db5003a4d7f8d-zfxvyoulnf.chromatic.com/?path=/story/pickr--default',
      stack: 'React, TS, Emotion',
      isPrivate: false,
    },
    {
      title: 'React Insect',
      description: 'Customisable input & select dropdown component for react.',
      github: 'https://github.com/kadetXx/insect',
      live: 'https://www.npmjs.com/package/react-insect',
      stack: 'React, TS',
      isPackage: true,
    },
  ],
  others: [
    {
      title: 'Petra Okelola',
      description: 'Product designer portfolio.',
      github: '',
      live: 'https://petra-okelola.vercel.app',
      stack: 'Nuxt3, TS, Gsap',
      isPrivate: true,
    },
    {
      title: 'Nick Jones',
      description: 'Golden ratio page layout using Fibonacci principles.',
      github: 'https://github.com/kadetXx/nick-jones-experimental',
      live: 'https://nickjones.vercel.app',
      stack: 'JS, CSS, HTML',
      isPrivate: false,
    },
    {
      title: 'Cedar',
      description: 'Audio/Video streaming',
      github: '',
      live: 'https://cedardemo.vercel.app',
      stack: 'NextJS, TRPC, Supabase',
      isPrivate: true,
    },
    {
      title: 'BevelPlexus',
      description: 'Fintech Dashboard UI',
      github: '',
      live: 'https://dev-bevelplexus.netlify.app/',
      stack: 'React, Context API',
      isPrivate: true,
    },
  ],
};
